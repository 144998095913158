@import '../themes';

.modal {
    .overlay {
        background: rgba($navy, 0.8);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $z-overlay;
    }

    .modal-content {
        background: $white;
        border-radius: 6px;
        box-shadow: 0 10px 10px rgba($black, 0.06), 0 2px 2px rgba($black, 0.08);
        color: $mid-grey;

        display: flex;
        flex-direction: column;
        font-family: $font-stack;
        left: 50%;
        margin-left: -300px;
        position: fixed;
        top: 100px;
        width: 600px;

        z-index: $z-modal;

        .header-container {
            width: 100%;
            height: 55px;
            border-bottom: 1px solid $border-color-primary;
            display: flex;
            flex: 0 0 auto;
            justify-content: space-between;
            padding: 0 16px;

            .header-logo-container {
                align-items: center;
                display: flex;
                height: 100%;

                svg {
                    width: 24px;
                    height: 24px;
                    fill: $black;
                }

                .header-title {
                    color: $black;
                    margin-left: 12px;
                    size: 16px;
                }
            }

            .close-icon {
                align-items: center;
                cursor: pointer;
                display: flex;
                justify-content: flex-end;

                // Makes the "button" width wider than the actual icon
                margin-right: -16px;
                opacity: 0.7;
                padding-left: 16px;
                padding-right: 16px;

                &:hover {
                    opacity: 1;
                }

                svg {
                    fill: $black;
                }
            }

        }

        .modal-main-content {
            height: 100%;
            margin-top: 16px;

            .help-text-container {
                width: 100%;
                height: 100px;
                align-items: center;
                display: flex;
                justify-content: center;

                .help-text-content {
                    width: 90%;
                    height: 68px;
                    align-items: center;
                    background-color: $background-color-tertiary;
                    border-radius: 6px;
                    display: flex;
                    padding: 8px;

                    .info-icon {
                        align-items: center;
                        display: flex;
                        height: 100%;
                        justify-content: center;
                        margin: 0 16px 0 8px;

                        svg {
                            fill: $text-color-quaternary;
                        }
                    }

                    .help-text-info {
                        color: $text-color-quaternary;
                        display: flex;
                        flex-direction: column;
                        font-family: $font-stack;
                        font-size: 12px;

                        >:not(:last-child) {
                            margin-bottom: 4px;
                        }
                    }

                    .help-text-user-info {
                        color: $text-color-quaternary;
                        font-family: $font-stack;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            form {
                padding: 0 45px;

                input.error {
                    border-color: $button-error-color-primary;
                }

                .error-text {
                    align-items: center;
                    display: flex;
                    float: right;
                    position: relative;
                    top: 14px;

                    .error-icon {
                        align-items: center;
                        display: flex;
                        height: 100%;

                        svg {
                            width: 12px;
                            height: 13px;
                            fill: $button-error-color-primary;
                        }
                    }

                    .error-label {
                        align-items: flex-end;
                        color: $button-error-color-primary;
                        display: flex;
                        font-size: 10px;
                        font-weight: 600;
                        height: 100%;
                        margin-left: 5px;
                        text-transform: uppercase;
                    }
                }
            }

            section > & {
                border-bottom: $border;
                margin-bottom: 20px;
                padding: 0 15px;
                padding-bottom: 20px;

                p {
                    font-size: 13px;
                    line-height: 1.8;
                    padding-bottom: 5px;
                }
            }

            form {
                padding: 0 45px;
            }
        }

        .modal-footer {
            width: 100%;
            height: 80px;
            align-items: center;
            display: flex;
            flex: 0 0 auto;
            justify-content: flex-end;

            padding-right: 20px;
            text-align: right;

            .footer-cancel {
                margin-right: 16px;
            }
        }

        label {
            line-height: 2.4;
            margin-bottom: 20px;

            @include select-dropdown;

            &:hover {
                color: $mid-grey;

                input,
                select {
                    border-color: $light-grey;
                }
            }

            .error-text {
                color: $error-color-primary;
            }

            input {
                border: $border;
                border-radius: 6px;
                color: $black;
                display: block;
                font-size: 14px;
                padding: 10px 16px;
                width: 100%;
            }

            span {
                color: $mid-grey;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1px;
                line-height: 2.6;
                position: relative;
                text-transform: uppercase;
                top: 4px;
            }
        }
    }
}
