@import 'styles/typography';

.organization-details-page-container {
    flex: 1 1 auto;
    display: flex;

    .organization-details-tab-container {
        flex: 1 1 auto;
    }
    .organization-details-tab {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .organization-details-edit {
        height: 100%;
        width: 100%;
    }

    .user-list-table {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;

        .user-list-table-info {
            flex: 0 0 100%;
            display: flex;
            padding: 0px 16px;
            padding-bottom: 10px;

            .left-info-panel {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 6px 6px 0 0;
            }

            .info-panel {
                display: flex;
                flex-direction: column;
                width: 335px;
                padding-left: 10px;
                border-radius: 6px;
                color: $text-color-quaternary;
                background-color: $background-color-tertiary;


                .info-panel-title {
                    height: 30px;
                    display: flex;
                    align-items: center;
                    @include title-medium-large;

                    .icon-circled-info {
                        margin-right: 4px;
                    }
                }

                .info-panel-subtitle {
                    height: 40px;
                    display: flex;
                    flex-direction: column;

                    .state {
                        .state-label {
                            @include label-medium-bold;
                        }

                        .state-description {
                            font-family: $font-stack;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .user-list-table-container {
            flex: 1 1 auto;
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 0 6px 6px 6px;
            background-color: $white;

            .ag-grid-container {
                .navigation-cell {
                    height: 20px;
                    display: flex;
                    align-items: center;

                    cursor: pointer;
                    border-bottom: 2px solid transparent;
                    color: $dark-grey;

                    &:hover {
                        color: $black;
                        border-bottom-color: $yellow;
                    }
                }

                .cell-renderer-button {
                    justify-content: 'center';
                }

                .delete-btn {
                    height: 26px;
                    width: 100px;
                    padding: 0 12px;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    cursor: pointer;

                    .delete-icon {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        margin-right: 8px;

                        svg {
                            fill: $darker-grey;
                        }
                    }

                    .delete-label {
                        color: $darker-grey;
                        @include label-medium;
                    }

                    &:hover {
                        .delete-label {
                            color: $black;
                        }

                        .delete-icon svg {
                            fill: $black;
                        }
                    }
                }
            }
        }
    }
}
