@import 'styles/modules/loadable';
@import 'styles/utils/variables';

@mixin button-generic {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: $font-stack;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    user-select: none;
}

@mixin button-primary(
    $background-color: $button-color-primary,
    $loading-background-color: $text-color-quaternary,
    $text-color: $white
) {
    @include button-generic;
    background-color: $background-color;
    border-radius: 6px;
    color: $text-color;

    opacity: 0.8;
    overflow: hidden;
    padding: 0 16px;

    position: relative;

    &.disabled:not(.loading):hover {
        opacity: 0.8;
    }

    &:hover,
    &.loading {
        opacity: 1;
    }

    &.disabled {
        background-color: lighten($light-grey, 15%);
        color: $light-grey;
        cursor: default;
    }

    @include loadable($loading-background-color, 'spinner-white.gif', 34px);

    &.small {
        height: 35px;
    }

}

@mixin button-tertiary {
    @include button-generic;
    color: $black;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    opacity: 0.8;
    padding: 0 16px;

    &:hover {
        background-color: $background-color-tertiary;
        opacity: 1;
    }
}

.button-primary {
    @include button-primary;
}

.button-secondary {
    @include button-generic;
    color: $black;
    padding: 0 8px;

    &:hover {
        text-decoration: underline;
    }
}

.button-tertiary {
    @include button-tertiary;
}


@mixin list-item {
    align-items: center;
    background-color: $white;
    color: $black;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 36px;
    padding: 0 8px;

    &:not(:first-child):not(:last-child) {
        border-bottom: 1px solid rgba($border-color, 0.6);
    }

    &:hover {
        color: $text-color-quaternary;
        opacity: 1;
        text-decoration: underline;
    }
}


@mixin select-dropdown {
    select {
        width: 100%;
        height: 43px;
        appearance: none;
        background-color: $white;
        border: $border;
        border-radius: 6px;
        box-sizing: border-box;
        color: $black;
        display: block;
        font: inherit;
        font-size: 13px;
        line-height: 1.5em;
        margin: 0;
        padding: 0 10px;

        &.minimal {
            background: url("data:image/svg+xml,%3csvg viewBox='0 0 9 5' stroke='%238a939d' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L4.25 4.25L7.5 1' stroke-width='1.08333' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e") no-repeat right 12px center/0.8em 1rem;

            &:hover {
                border-color: darken($border-color, 10%);
            }
        }
    }
}
